.tippy-tooltip.iw-theme{
    color:#26323d;
	max-width: 400px;
    border: 1px solid rgba(232,229,225,0.9);
    background-color: rgba(184,178,169,0.65);
	background-clip:padding-box;
    -webkit-box-shadow: 0 0 4px rgba(0,0,0,0.65);
    -moz-box-shadow: 0 0 4px rgba(0,0,0,0.65);
    box-shadow: 0 0 4px rgba(0,0,0,0.65);
    padding: 2px;
	border-radius: 0;
	font-size: 1rem;
}
.tippy-tooltip.iw-theme .tippy-content{
	padding:8px 8px;
    border:1px solid #7b746e;
    background-color:rgba(255,255,255,0.85);
    background-image:-owg-linear-gradient(to bottom,#fff 0,#fff 0,transparent 40px);
    background-image:-webkit-linear-gradient(to bottom,#fff 0,#fff 0,transparent 40px);
    background-image:-moz-linear-gradient(to bottom,#fff 0,#fff 0,transparent 40px);
    background-image:-o-linear-gradient(to bottom,#fff 0,#fff 0,transparent 40px);
    background-image:linear-gradient(to bottom,#fff 0,#fff 0,transparent 40px);
    word-wrap:break-word
}
.tippy-tooltip.iw-theme .tippy-backdrop{
    background-color:#fff
}
.tippy-tooltip.iw-theme .tippy-arrow{
    background-image: url(//iwgame.g.bsrv.su/static/img/sprites/general.png);
}
.tippy-tooltip.iw-theme[x-placement^=left] .tippy-arrow,
.tippy-tooltip.iw-theme[x-placement^=right] .tippy-arrow,
.tippy-tooltip.iw-theme[x-placement^=top] .tippy-arrow,
.tippy-tooltip.iw-theme[x-placement^=bottom] .tippy-arrow{
	border-left: none;
    border-right: none;
    border-top: none;
    border-bottom: none;
    margin: 3px 0;
    transform-origin: 0 50%;
}
.tippy-tooltip.iw-theme[x-placement^=left] .tippy-arrow,
.tippy-tooltip.iw-theme[x-placement^=right] .tippy-arrow{
	width: 12px;
    height: 16px;
}
.tippy-tooltip.iw-theme[x-placement^=top] .tippy-arrow,
.tippy-tooltip.iw-theme[x-placement^=bottom] .tippy-arrow{
	width: 16px;
    height: 12px;
}
.tippy-tooltip.iw-theme[x-placement^=left] .tippy-arrow{
	right: -9px;
	background-position:-441px -373px;
}
.tippy-tooltip.iw-theme[x-placement^=right] .tippy-arrow{
	left: -9px;
	background-position:-441px -401px;
}
.tippy-tooltip.iw-theme[x-placement^=top] .tippy-arrow{
	bottom: -12px;
	background-position:-441px -361px;
}
.tippy-tooltip.iw-theme[x-placement^=bottom] .tippy-arrow{
	top: -12px;
	background-position:-441px -389px;
}